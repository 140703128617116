export const decimalTwoFormat = (value) => {
  if (!value) return "0.00";
  const valueLocale = thousandsSeparator(value);
  const [fullNumbe, digit] = valueLocale.split(".");
  return digit ? fullNumbe + "." + digit.substring(0, 2) : fullNumbe + ".00";
};

export const decimalFourFormat = (value) => {
  if (!value) return "0.0000";
  const valueLocale = thousandsSeparator(value);
  const [fullNumbe, digit] = valueLocale.split(".");
  return digit ? fullNumbe + "." + digit.substring(0, 4) : fullNumbe + ".0000";
};

export const thousandsSeparator = (value) => {
  return value.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 });;
};