import axios from "axios";
import qs from "qs";
import { authState } from "../states/auth";
import { useRecoilState } from "recoil";

const axiosRef = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

function clearAccessToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("name");
}

export function useAPI() {
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);
  const [authToken, setAuthToken] = useRecoilState(authState.authToken);
  const [profile, setProfile] = useRecoilState(authState.profile);

  const handleResponse = async (apicall) => {
    try {
      const result = await apicall;
      return result.data;
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        clearAccessToken();
        setIsLogin(null);
      }
      throw error?.response?.data;
    }
  };

  const loginMerchant = async (username, password) => {
    return handleResponse(
      axiosRef.post("/login", {
        username,
        password
      })
    );
  };

  const profileMerchant = async () => {
    return handleResponse(
      axiosRef.get("/merchant", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      })
    );
  };

  const balanceMerchant = async (tokenStr) => {
    return handleResponse(
      axiosRef.get("/merchant/balance", {
        headers: { Authorization: `Bearer ${tokenStr}` }
      })
    );
  };

  const historyDepositList = async (status, date_start, date_end, page, keyword) => {
    const searchDepositParams = qs.stringify({
      start_date: date_start || "",
      end_date: date_end || "",
      status: !!status ? status : "",
      order_by: `dateCreated|desc`,
      page,
      client_id: profile?.setting[0]?.clientId,
      keyword
    });

    return handleResponse(
      axiosRef.get(`/qrcode/transactions/history?${searchDepositParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const pendingDepositList = async (
    keyword,
    date_start,
    date_end,
    page = 1
  ) => {
    const searchDepositParams = qs.stringify({
      keyword,
      start_date: date_start || "",
      end_date: date_end || "",
      order_by: `dateCreated|desc`,
      page,
      client_id: profile?.setting[0]?.clientId
    });

    return handleResponse(
      axiosRef.get(`/qrcode/transactions/pending?${searchDepositParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const withdrawList = async (status, date_start, date_end) => {
    const statusMap = {
      1: "successful",
      2: "pending",
      3: "failed",
      4: "holded"
    };

    const searchWithdrawParams = qs.stringify({
      start: date_start || "",
      end: date_end || "",
      status: status && status != "" ? statusMap[status] : "",
      orderby: `date_created|desc`
    });

    return handleResponse(
      axiosRef.get(
        `/withdraw/transactions?${searchWithdrawParams}`,
        // {
        //   status,
        //   date_start,
        //   date_end,
        //   type
        // },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
    );
  };

  const sumDepositWithdraw = async (date_start, date_end) => {
    return handleResponse(
      axiosRef.post(
        "/transaction/sum",
        {
          date_start,
          date_end
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
    );
  };

  const updateMerchant = async (callback, callbackWithdraw, minimumBalance, name) => {
    return handleResponse(
      axiosRef.post(
        "/merchant/save",
        {
          callback,
          callback_withdraw: callbackWithdraw,
          minimum_balance: minimumBalance,
          name
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
    );
  };

  const transactionHook = async (
    referenceNo,
    resultCode = "00",
    fee = 0,
    vat = 0
  ) => {
    return handleResponse(
      axiosRef.post(
        "/transaction/hook",
        {
          referenceNo,
          resultCode,
          fee,
          vat
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
    );
  };

  const getPostUrl = async () => {
    return handleResponse(
      axiosRef.get("/merchant/url", {
        headers: { Authorization: localStorage.getItem("token") }
      })
    );
  };

  const updateUrl = async (post_back_url, post_back_withdraw_url) => {
    return handleResponse(
      axiosRef.post(
        "/merchant/update_url",
        {
          post_back_url,
          post_back_withdraw_url
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
    );
  };

  const updateKyc = async (formData) => {
    return handleResponse(
      axiosRef.post("/merchant/kyc", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token")
        }
      })
    );
  };

  const registerMerchant = async (merchant_name, email, phone, password) => {
    return handleResponse(
      axiosRef.post(
        "/merchant/register",
        {
          merchant_name,
          email,
          phone,
          password
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
    );
  };

  const tokenList = async () => {
    return handleResponse(
      axiosRef.get("/credential", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const changePassword = async (new_password, new_password_confirm) => {
    return handleResponse(
      axiosRef.post(
        "/user/change-password",
        {
          new_password,
          new_password_confirm
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
    );
  };

  const generateCredential = async () => {
    return handleResponse(
      axiosRef.post(
        "/credential/generate",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
    );
  };

  const withdrawCustomer = async (
    amount,
    bank_code,
    branch_code,
    account,
    account_name
  ) => {
    return handleResponse(
      axiosRef.post(
        "/withdraw/submit",
        {
          amount,
          bank_code,
          branch_code,
          account,
          account_name
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
    );
  };

  const recoveryPassword = async (email) => {
    return handleResponse(
      axiosRef.post(
        "/password/recovery",
        {
          email
        },
        {
          headers: {}
        }
      )
    );
  };

  const resetPassword = async (token, new_password, new_password_confirm) => {
    return handleResponse(
      axiosRef.post(
        "/password/reset",
        {
          token,
          new_password,
          new_password_confirm
        },
        {
          headers: {}
        }
      )
    );
  };

  const requestTopups = async (amount) => {
    return handleResponse(
      axiosRef.post(
        "/topup/request",
        {
          amount
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        }
      )
    );
  };

  const getAllRequestTopups = (status) => {
    const params = {};
    if (status && status !== "") {
      params["status"] = status;
    }
    return handleResponse(
      axiosRef.get("/topup/request", {
        params,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      })
    );
  };

  const uploadEvidenceTopup = (datas) => {
    return handleResponse(
      axiosRef.post("/topup/slip/submit", datas, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const getSlipList = (status) => {
    const params = {};
    if (status) {
      params["status"] = status;
    }
    return handleResponse(
      axiosRef.get("/topup/slip", {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const getCustomer = () => {
    const params = {};
    return handleResponse(
      axiosRef.get("/customer", {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const getExportDepositURL = (date_start, date_end) => {
    const searchDepositParams = qs.stringify({
      start_date: date_start || "",
      end_date: date_end || "",
      client_id: profile?.setting[0]?.clientId
    });

    return handleResponse(
      axiosRef.get(`/qrcode/transactions/download?${searchDepositParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  const exportDeposit = (token) => {
    const exportDepositParams = qs.stringify({
      token
    });

    return handleResponse(
      axiosRef.get(`/qrcode/transactions/export?${exportDepositParams}`)
    );
  };

  const genDepositQRCode = (amount) => {
    return handleResponse(
      axiosRef.post(
        "/qrcode/gen_qr",
        {
          amount,
          client_id: profile?.setting[0]?.clientId
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        }
      )
    );
  };

  const depositStatus = async (
    transaction_id,
  ) => {
    const searchDepositParams = qs.stringify({
      transaction_id,
      client_id: profile?.setting[0]?.clientId
    });

    return handleResponse(
      axiosRef.get(`/qrcode/transaction?${searchDepositParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    );
  };

  return {
    loginMerchant,
    historyDepositList,
    pendingDepositList,
    profileMerchant,
    withdrawList,
    sumDepositWithdraw,
    transactionHook,
    getPostUrl,
    updateUrl,
    registerMerchant,
    updateKyc,
    tokenList,
    withdrawCustomer,
    generateCredential,
    balanceMerchant,
    updateMerchant,
    changePassword,
    recoveryPassword,
    resetPassword,
    requestTopups,
    getAllRequestTopups,
    uploadEvidenceTopup,
    getSlipList,
    getCustomer,
    getExportDepositURL,
    exportDeposit,
    genDepositQRCode,
    depositStatus
  };
}
